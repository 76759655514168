@import 'styles/vars.scss';

.alert {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  // width: 0;

  // &.compact {
  //   display: inline-block;
  // }

  &.danger {
    .container {
      background: rgba($red, 0.3);
      border-color: $red;
      color: $red;
    }
  }

  &.success {
    .container {
      background: rgba($green, 0.3);
      border-color: $green;
      color: $green;
    }
  }

  &.orange {
    .container {
      background: #ffcd1e;
      border-color: #000;
      color: #000;
    }
  }

  &.neutral {
    .container {
      background: #2a2c30;
      color: #8d99a8;

      .content {
        .title {
          color: #fff;
        }
      }
    }
  }

  .container {
    padding: 16px 20px 14px 18px;
    border-radius: 8px;
    line-height: 1.4;
    display: flex;
    flex-flow: row nowrap;

    .icon {
      margin-right: 14px;
      flex: 0 0 0;
    }

    .content {
      flex: 1 1 0px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      .title {
        font-weight: bold;
        margin-bottom: 4px;
      }
    }
  }

  a {
    font-style: normal;
    color: #1a8cff;
    text-decoration: none;
    cursor: pointer;
  }
}
