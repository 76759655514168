@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.field {
  @include field;

  .input {
    max-width: 300px;
    border-radius: $inputBorderRadius;
    width: 100%;
    @include rw400;
    padding: $inputPadding;
    display: block;
    outline: none;
    background: $neutrals-20;
    border: 1px solid $neutrals-20;
    color: $text-primary;

    &:focus {
      border-color: $blueLight !important;
    }

    &.fullWidth {
      max-width: none;
    }

    &.invalid {
      border-color: $red;
    }
  }

  &.disabled .input {
    pointer-events: none;
    opacity: 0.5;
  }
}
