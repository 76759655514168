$red: #dd5050;
$orange: #ffcd1e;
$green: #1e9800;
$brown: #c9ba9b;
$blue: #00c;
$blueLight: #2684ff;
$black: #000;
$white: #fff;
$dark: #2a383d;
$grayDark: #666;
$gray: #728085;
$grayLight: #e8eef0;
$light: #eee;

$primary: #d3e2e9;
$background: #191a1d;

$brand-green: #44913f;
$system-pure-white: #ffffff;
$system-pure-black: #000000;
$system-error-red: #eb281d;
$system-attention-orange: #ff7d0c;
$system-links-blue: #1f5ebf;
$text-white: #ffffff;
$text-primary: #2a383d;
$text-secondary: #4e5c61;
$text-trietary: #728085;
$text-quaternary: #9ca9ad;
$border-dark: #d3dde0;
$border-light: #e8eef0;
$neutrals-80: #2a383d;
$neutrals-60: #4e5c61;
$neutrals-50: #728085;
$neutrals-40: #9ca9ad;
$neutrals-30: #d3dde0;
$neutrals-20: #ebf0f2;
$neutrals-10: #f7f9fa;

$defaultBackgroundColor: $black;
$defaultTextColor: $primary;

$sans: "Inter", sans-serif;

$defaultFontSize: 16px;

$paragraphLineHeight: 1.4;
$defaultLineHeight: 1.2;

$screenPadding: 20px;
$wrapperMaxWidth: 100%;

$h1Size: 42px * 0.8;
$h2Size: 36px * 0.8;
$h3Size: 28px * 0.8;
$h4Size: 22px * 0.8;
$h5Size: 16px * 0.8;

$h1SizeMobile: 36px * 0.8;
$h2SizeMobile: 28px * 0.8;
$h3SizeMobile: 22px * 0.8;
$h4SizeMobile: 16px * 0.8;
$h5SizeMobile: 14px * 0.8;

$h1LineHeight: 1.1;
$h2LineHeight: 1.1;
$h3LineHeight: 1.2;
$h4LineHeight: 1.2;
$h5LineHeight: 1.3;

$linkBorderOpacity: 0.4;
$inputPadding: 7px 13px;
$inputBorderRadius: 4px;
$pageContentPaddingTop: 3em;
$pageContentPaddingBottom: 5em;

$breakpoints: (
  mobile: 600px,
) !default;
