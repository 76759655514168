@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.link {
  font-style: normal;
  color: #1a8cff;
  text-decoration: none;
  cursor: pointer;
}

.stepHeader {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;

  .pic {
    max-width: 100%;
    height: auto;
    margin-bottom: 16px;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: #d3e2e9;
  }
  .desc {
    margin-top: 13px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.2px;
    color: #8994a9;
  }
  .bottomPic {
    max-width: 300px;
    width: 100%;
    height: auto;
    margin-top: 24px;
  }
  .bottomPicQr {
    max-width: 200px;
    width: 100%;
    height: auto;
    margin-top: 24px;
  }
}

.directionMenu {
  .item {
    background: #1c1e21;
    border-radius: 12px;
    padding: 18px 16px 20px 52px;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      position: relative;

      .icon {
        position: absolute;
        left: -36px;
      }
      .title {
        flex: 1 1 100%;
        font-style: normal;
        font-weight: 510;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: -0.2px;
        color: rgba(239, 242, 251, 0.92);
        padding-right: 20px;
      }
      .rightArrow {
        flex: 0 0 auto;
      }
    }

    .desc {
      margin-top: 6px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -0.2px;
      color: #8994a9;
    }
  }
}

.requisites {
  .header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      display: flex;
      align-items: flex-end;
      letter-spacing: -0.2px;
      color: rgba(239, 242, 251, 0.92);
    }
    .copy {
      background: none;
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      display: flex;
      align-items: flex-end;
      letter-spacing: -0.2px;
      color: #1a8cff;
      border: none;
      cursor: pointer;
    }
  }
  .items {
    .item {
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 19px;
      }

      .left {
        flex: 1 1 100%;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 130%;
          letter-spacing: -0.2px;
          color: #8994a9;
        }
        .value {
          margin-top: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 130%;
          letter-spacing: -0.2px;
          color: rgba(239, 242, 251, 0.92);
        }
      }
      .right {
        flex: 0 0 40px;
      }
    }
  }
}

.instructions {
  margin-top: 48px;

  .item {
    position: relative;
    padding-left: 39px;

    &:not(:last-child) {
      padding-bottom: 40px;

      &:before {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        left: 11px;
        top: 0;
        border-left: 2px dashed rgba(239, 242, 251, 0.92);
      }
    }

    .number {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background: rgba(239, 242, 251, 1);
      border: 1px solid rgba(137, 148, 169, 0.8);
      border-radius: 24px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 590;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
      letter-spacing: -0.2px;
      color: #1c1e21;
    }

    .title {
      font-style: normal;
      font-weight: 590;
      font-size: 18px;
      line-height: 130%;
      letter-spacing: -0.2px;
      color: rgba(239, 242, 251, 0.92);
    }

    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -0.2px;
      color: #8994a9;
      margin-top: 8px;
    }

    .buttonPlace {
      margin-top: 24px;
      display: flex;
      flex-flow: row nowrap;
      .button {
      }
    }
  }
}

.sumInfo {
  background: rgba(194, 201, 214, 0.14);
  border-radius: 12px;
  padding: 14px 16px 16px 44px;
  position: relative;
  margin-bottom: 32px;

  .icon {
    position: absolute;
    top: 14px;
    left: 14px;
  }
  .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.2px;
    color: rgba(239, 242, 251, 0.92);
    opacity: 0.8;
  }
}
.sumProperty {
  background: #ffcd1e;
  border-radius: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 32px;
  padding: 13px 40px 13px 17px;

  .icon {
    flex: 0 0 auto;
  }
  .title {
    flex: 1 1 100%;
    padding-left: 17px;
    font-style: normal;
    font-weight: 590;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.2px;
    color: #1a1d23;
  }
}

.backButton {
}
