@import 'styles/vars.scss';
@import 'styles/mixins.scss';

:global {
  // .ReactModal__Html--open,
  // .ReactModal__Body--open {
  //   // overflow: hidden;
  // }

  .ReactModal__Overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: background-color 200ms ease;
    // background: rgba(134, 136, 141, 0);
    // backdrop-filter: blur(0px);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    align-items: flex-end;

    // @include media("<700px") {
    //   justify-content: flex-end;
    // }
  }

  .ReactModal__Content {
    background: #000;
    outline: none;
    max-width: 700px;
    width: calc(100vw - 64px);
    // max-width: calc(100vw - 2rem);
    // max-height: calc(100vh - 2rem);
    height: 100%;
    position: relative;
    // border-radius: 4px;
    transition: margin 200ms ease, opacity 200ms ease;
    // margin-bottom: -100vh;

    @include media('<600px') {
      height: calc(100vh - 48px);
      width: 100%;

      // padding: 16px 20px 80px;
      // width: 100% !important;
      // max-width: 100%;
      // border-radius: 8px 8px 0 0;
    }
  }
}

.content {
  padding: 40px 60px 153px;
  overflow-y: auto;
  height: 100%;

  @include media('<600px') {
    padding: 20px 20px 130px;
  }

  .title {
    @include h400;

    @include media('<700px') {
      @include h300;
    }
  }

  .desc {
    margin-top: 16px;
  }
}

.cross {
  position: absolute;
  top: 0;
  left: -64px;
  border: none;
  padding: 12px;
  width: 40px;
  height: 40px;
  box-sizing: content-box;
  outline: none;
  cursor: pointer;
  opacity: 0.85;
  transition: opacity 400ms ease;
  background: transparent;

  @include media('<600px') {
    top: -54px;
    right: 0;
    left: auto;
  }

  &:hover {
    opacity: 1;
    transition: none;
  }

  path {
    fill: #fff;
  }

  // @include media("<900px") {
  //   padding: 22px;
  // }

  // svg {
  //   // width: 17px;
  //   // height: 17px;

  //   path {
  //     fill: $text-trietary;
  //   }

  //   @include media("<900px") {
  //     // width: 13px;
  //     // height: 13px;
  //   }
  // }
}
