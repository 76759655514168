@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.radioButtons {
  @include field;

  &.direction-row {
    .items {
      display: flex;
      flex-flow: row wrap;
      margin-right: -10px;
      margin-bottom: -5px;

      .radioButton {
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }

  &.direction-column {
    .items {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      margin-bottom: -5px;

      .radioButton {
        margin-bottom: 5px;
      }
    }
  }
}

.radioButton {
  font-size: $defaultFontSize;
}
