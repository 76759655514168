@import 'styles/mixins.scss';
@import 'styles/vars.scss';

$headerBreakpoint: 600px;
$headerBreakpoint1: 400px;

img {
  max-width: 100%;
}

.section {
  overflow: hidden;

  .sectionTitle {
    font-weight: 700;
    font-size: 56px;
    line-height: 105%;
    text-align: center;
    color: rgba(239, 242, 251, 0.92);
    @include media('<=#{$headerBreakpoint}') {
      text-align: left;
      font-size: 34px;
    }
    @include media('<=#{$headerBreakpoint1}') {
      font-size: 28px;
    }
  }

  .sectionDesc {
    margin-top: 31px;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.2px;
    color: #8994a9;
    @include media('<=#{$headerBreakpoint}') {
      text-align: left;
      margin-top: 16px;
      font-size: 16px;
    }
  }
}

.wrapper {
  // box-sizing: content-box;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1120px + 16px * 2;
  width: 100%;
  margin: 0 auto;
}

.section.header {
  position: relative;
  height: 84px;
  @include media('<=#{$headerBreakpoint}') {
    height: 52px;
  }

  .headerInner {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;

    .wrapper {
      padding-top: 24px;
      padding-bottom: 16px;
      background-color: #000;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      @include media('<=#{$headerBreakpoint}') {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .logoPlace {
        font-size: 0;

        .logoBigLink {
          @include media('<=#{$headerBreakpoint}') {
            display: none;
          }
        }

        .logoSmallLink {
          @include media('>#{$headerBreakpoint}') {
            display: none;
          }
        }
      }

      .authMenu {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;

        .authMenuItem {
          &:not(:first-child) {
            margin-left: 6px;
          }

          @include media('<=350px') {
            margin-left: -6px !important;
          }

          .authMenuItemIcon {
            @include media('<=#{$headerBreakpoint}') {
              display: none;
            }
          }
        }
      }
    }
  }
}

.section.ref {
  margin-top: 32px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 26px;
  }

  .wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .panel {
      padding: 8px 16px;
      background: #eff2fb;
      border-radius: 9999px;
      display: inline-block;

      font-style: normal;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
      letter-spacing: -0.2px;
      color: #1c1e21;
    }
  }
}

.section.intro {
  margin-top: 53px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 33px;
  }

  .wrapper {
    .sectionTitle {
      @include media('<=#{$headerBreakpoint}') {
        text-align: center;
      }
    }
    .sectionDesc {
      margin-top: 16px;
      @include media('<=#{$headerBreakpoint}') {
        margin-top: 12px;
        text-align: center;
      }
    }

    .break {
      @include media('<=#{$headerBreakpoint}') {
        display: none;
      }
    }
    .buttonPlace {
      margin-top: 32px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      @include media('<=#{$headerBreakpoint}') {
        margin-top: 28px;
      }
    }

    .picPlace {
      margin-top: 48px;
      text-align: center;

      @include media('<=#{$headerBreakpoint}') {
        margin-top: 28px;
      }

      .pic {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

$directionsBreakpoint: 1000px;
.section.directions {
  margin-top: 96px;
  @include media('<=#{$directionsBreakpoint}') {
    margin-top: 74px;
  }

  .wrapper {
    .items {
      margin-top: 48px;
      margin-left: -12px;
      margin-right: -12px;
      margin-bottom: -24px;
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      @include media('<=#{$directionsBreakpoint}') {
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: -16px;
      }

      .item {
        // flex: 0 0 50%;
        flex: 0 0 33.33%;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 24px;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        @include media('<=#{$directionsBreakpoint}') {
          flex: 0 0 100%;
          padding-left: 0px;
          padding-right: 0px;
          padding-bottom: 16px;
        }

        .itemInner {
          padding: 32px 32px 40px 32px;
          border: 1px solid rgba(194, 201, 214, 0.26);
          border-radius: 16px;
          @include media('<=#{$directionsBreakpoint}') {
            padding: 24px 24px 32px 24px;
          }

          .itemPicPlace {
            .itemPic {
              max-width: 100%;
              height: auto;
            }
          }

          .itemTitle {
            @include media('<=#{$directionsBreakpoint}') {
              margin-top: 12px;
              font-size: 20px;

              span {
                display: none;
              }
            }
            margin-top: 16px;
            font-weight: bold;
            font-size: 24px;
            line-height: 130%;
            color: rgba(239, 242, 251, 0.92);
          }

          .itemDesc {
            @include media('<=#{$directionsBreakpoint}') {
              margin-top: 8px;
            }
            margin-top: 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.2px;
            color: #8994a9;
          }
        }
      }
    }
  }
}

.section.properties {
  margin-top: 144px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 48px;
  }

  .wrapper {
    .itemsOuter {
      margin-top: 48px;
      position: relative;
      @include media('<=#{$headerBreakpoint}') {
        margin-top: 26px;
      }

      .arrowWrapper {
        height: 100%;
        width: 68px;
        top: 0;
        position: absolute;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        z-index: 100;
        user-select: none;

        &.leftArrowWrapper {
          background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 100%);
          left: -68px;
          transition: left 200ms ease;
          justify-content: left;
          padding-left: 12px;

          &.visible {
            left: 0;
          }
        }

        &.rightArrowWrapper {
          background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 100%);
          right: -68px;
          transition: right 200ms ease;
          justify-content: right;
          padding-right: 12px;

          &.visible {
            right: 0;
          }
        }
        .icon {
        }
      }

      .items {
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        &::-webkit-scrollbar {
          display: none;
        }

        .itemsInner {
          display: flex;
          flex-flow: row nowrap;
          align-items: flex-start;
          margin: 0 auto;
          // justify-content: center;
          // padding-left: 300px;

          .itemOuter {
            width: 357px;
            flex: 0 0 357px;
            padding-left: 24px;
            position: relative;
            @include media('<=#{$headerBreakpoint}') {
              width: 300px;
              flex: 0 0 300px;
            }

            &:last-child {
              padding-right: 24px;
            }

            .item {
              width: 100%;
              background: #1c1e21;
              border-radius: 12px;
              overflow: hidden;

              .itemPicPlace {
                height: 160px;
                position: relative;

                .itemDate {
                  position: absolute;
                  z-index: 2;
                  left: 12px;
                  top: 13px;
                  padding: 3px 12px 4px;
                  background: #1a1d23;
                  border-radius: 13px;
                  font-style: normal;
                  font-size: 14px;
                  line-height: 130%;
                  text-align: center;
                  letter-spacing: -0.2px;
                  color: rgba(239, 242, 251, 0.92);
                }

                .itemPic {
                  z-index: 1;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
              .itemContent {
                padding: 12px 16px 16px;

                .itemTitle {
                  font-size: 18px;
                  line-height: 130%;
                  letter-spacing: -0.2px;
                  color: rgba(239, 242, 251, 0.92);
                  white-space: nowrap;
                  overflow: hidden;
                  position: relative;

                  &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 16px;
                    height: 100%;
                    background: linear-gradient(90deg, rgba(#1c1e21, 0%) 0%, #1c1e21 100%);
                  }
                }

                .stats {
                  margin-top: 16px;
                  display: flex;
                  flex-flow: row nowrap;
                  justify-content: space-between;

                  .stat {
                    font-size: 16px;
                    line-height: 130%;
                    letter-spacing: -0.2px;
                    color: #8994a9;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;

                    .statIcon {
                      margin-right: 4px;
                    }
                  }
                }

                .progress {
                  margin-top: 12px;
                  display: flex;
                  flex-flow: row nowrap;
                  padding-left: 2px;
                  padding-right: 0px;

                  .filled,
                  .empty {
                    height: 8px;
                    border-radius: 12px;
                  }

                  .filled {
                    background: #f2a60d;
                    margin-left: -2px;
                  }

                  .empty {
                    margin-left: 4px;
                    background: rgba(194, 201, 214, 0.2);
                  }
                }
                .money {
                  margin-top: 12px;
                  font-size: 16px;
                  line-height: 130%;
                  letter-spacing: -0.2px;
                  display: flex;
                  flex-flow: row nowrap;
                  justify-content: space-between;
                  // white-space: nowrap;

                  .neededToCollect {
                    color: #f2a60d;
                  }
                  .leftToCellect {
                    text-align: right;
                    color: rgba(239, 242, 251, 0.92);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.section.dynamic {
  margin-top: 146px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 74px;
  }

  .wrapper {
    .items {
      margin-top: 52px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      margin-bottom: -12px;
      @include media('<=#{$headerBreakpoint}') {
        margin-top: 36px;
        justify-content: flex-start;
      }

      .item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 12px;

        &:not(:last-child) {
          margin-right: 36px;
          @include media('<=#{$headerBreakpoint}') {
            margin-right: 24px;
          }
        }

        .itemRound {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          border-radius: 50%;

          &.yellow {
            background-color: #f2a60d;
          }
          &.blue {
            background-color: #006c9a;
          }
          &.red {
            background-color: #da1919;
          }
        }
        .itemTitle {
          font-size: 14px;
          line-height: 130%;
          letter-spacing: -0.2px;
          color: rgba(239, 242, 251, 0.92);
        }
      }
    }
    .picPlace {
      margin-top: 28px;
      text-align: center;
      @include media('<=#{$headerBreakpoint}') {
        text-align: left;
      }

      .smallPic {
        @include media('>#{$headerBreakpoint}') {
          display: none;
        }
        width: 100%;
        height: auto;
      }
      .bigPic {
        @include media('<=#{$headerBreakpoint}') {
          display: none;
        }
        max-width: 100%;
        height: auto;
      }
    }
    .buttonPlace {
      margin-top: 24px;
      @include media('<=#{$headerBreakpoint}') {
        margin-top: 0;
      }
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  }
}

$riskBreakpoint: 900px;
$riskBreakpoint1: 700px;
.section.risk {
  margin-top: 144px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 96px;
  }

  .items {
    margin-top: 48px;
    display: flex;
    flex-flow: row wrap;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -24px;
    @include media('<=#{$riskBreakpoint}') {
      margin-top: 40px;
      margin-bottom: -16px;
    }

    .item {
      flex: 0 0 50%;
      padding-right: 12px;
      padding-left: 12px;
      padding-bottom: 24px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: stretch;
      align-items: stretch;
      @include media('<=#{$riskBreakpoint1}') {
        flex: 0 0 100%;
        padding-right: 12px;
        padding-left: 12px;
        padding-bottom: 16px;
      }

      .itemInner {
        border: 1px solid rgba(194, 201, 214, 0.26);
        border-radius: 16px;
        padding: 32px 32px 32px 96px;
        position: relative;
        @include media('<=#{$riskBreakpoint}') {
          padding: 24px 24px 32px 24px;
        }

        .itemIcon {
          position: absolute;
          top: 40px;
          left: 32px;
          width: 40px;
          height: 40px;

          @include media('<=#{$riskBreakpoint}') {
            position: relative;
            top: auto;
            left: auto;
            width: 56px;
            height: 56px;
          }
        }

        .itemTitle {
          font-size: 32px;
          line-height: 130%;
          color: rgba(239, 242, 251, 0.92);
          @include media('<=#{$riskBreakpoint}') {
            font-size: 20px;
            margin-top: 24px;
          }
        }

        .itemDesc {
          margin-top: 4px;
          font-size: 16px;
          line-height: 130%;
          letter-spacing: -0.2px;
          color: #8994a9;
          @include media('<=#{$riskBreakpoint}') {
            margin-top: 8px;
          }
        }
      }
    }
  }
}

$principBreakpoint: 950px;
.section.princip {
  margin-top: 144px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 48px;
  }

  .items {
    margin-top: 48px;
    display: flex;
    flex-flow: row wrap;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: -24px;
    @include media('<=#{$principBreakpoint}') {
      margin-top: 26px;
      margin-bottom: -20px;
    }

    .item {
      flex: 0 0 33%;
      padding-right: 12px;
      padding-left: 12px;
      padding-bottom: 24px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: stretch;
      align-items: stretch;
      @include media('<=#{$principBreakpoint}') {
        flex: 0 0 100%;
        padding-right: 12px;
        padding-left: 12px;
        padding-bottom: 16px;
      }

      .itemInner {
        border: 1px solid rgba(194, 201, 214, 0.26);
        border-radius: 16px;
        padding: 32px 40px 40px 40px;
        position: relative;
        @include media('<=#{$principBreakpoint}') {
          padding: 24px 24px 32px 24px;
        }

        .itemPic {
        }

        .itemTitle {
          font-size: 24px;
          line-height: 130%;
          color: rgba(239, 242, 251, 0.92);
          margin-top: 16px;
          @include media('<=#{$principBreakpoint}') {
            font-size: 20px;
          }
        }

        .itemDesc {
          font-size: 16px;
          line-height: 150%;
          letter-spacing: -0.2px;
          color: #8994a9;
          margin-top: 16px;
          @include media('<=#{$principBreakpoint}') {
            margin-top: 8px;
          }
        }
      }
    }
  }
}

$comfortBreakpoint: 1100px;
$comfortBreakpoint1: 1000px;
.section.comfort {
  margin-top: 96px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 42px;
  }

  .wrapper {
    .panel {
      background: #1c1e21;
      border-radius: 16px;
      position: relative;
      min-height: 523px;
      padding: 56px 56px 56px 56px;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;
      @include media('<=#{$comfortBreakpoint1}') {
        padding: 56px 56px 0 56px;
        min-height: auto;
        flex-flow: column;
      }
      @include media('<=#{$headerBreakpoint}') {
        padding: 24px 24px 0 24px;
        min-height: auto;
        flex-flow: column;
      }

      .text {
        display: flex;
        flex: 0 0 460px;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        @include media('<=#{$comfortBreakpoint1}') {
          flex: 1 1 auto;
        }

        .title {
          font-weight: bold;
          font-size: 48px;
          line-height: 105%;
          color: rgba(239, 242, 251, 0.92);
          @include media('<=#{$headerBreakpoint}') {
            font-size: 34px;
          }
        }

        .desc {
          margin-top: 32px;
          font-size: 18px;
          line-height: 130%;
          letter-spacing: -0.2px;
          color: rgba(239, 242, 251, 0.92);
          @include media('<=#{$headerBreakpoint}') {
            margin-top: 24px;
          }
        }
      }

      .iphonePlace {
        position: absolute;
        bottom: 0;
        right: 50px;
        font-size: 0;
        text-align: center;
        @include media('<=#{$comfortBreakpoint}') {
          right: -50px;
        }
        @include media('<=#{$comfortBreakpoint1}') {
          position: relative;
          bottom: auto;
          right: auto;
          margin-top: 30px;
          margin-right: -40px;
          margin-left: -40px;
        }

        .iphone {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

.section.form {
  margin-top: 96px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 48px;
  }

  .wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .sectionTitle {
      font-size: 48px;
      @include media('<=#{$headerBreakpoint}') {
        font-size: 34px;
      }
    }

    .panelOuter {
      flex: 0 1 800px;
      position: relative;

      .handLeft {
        position: absolute;
        top: 160px;
        left: -591px;
      }

      .handRight {
        position: absolute;
        top: 49px;
        right: -601px;
      }

      .panel {
        padding: 48px;
        width: 100%;
        background: #000000;
        border: 2px solid #f2a60d;
        border-radius: 16px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        @include media('<=#{$headerBreakpoint}') {
          padding: 48px 26px;
        }

        .sectionTitle {
        }
        .sectionDesc {
        }

        .formOuter {
          width: 100%;
          max-width: 432px;
          margin-top: 48px;
        }

        .rules {
          margin-top: 24px;
          font-size: 14px;
          line-height: 130%;
          text-align: center;
          letter-spacing: -0.2px;
          color: #8994a9;

          a {
            color: #1a8cff;
            text-decoration: none;
          }
        }
      }
    }
  }
}

$moreBreakpoint: 700px;
.section.more {
  margin-top: 144px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 72px;
  }

  .wrapper {
    .sectionTitle {
    }

    .items {
      margin-top: 48px;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      margin-left: -12px;
      margin-right: -12px;
      margin-bottom: -16px;
      @include media('<=#{$headerBreakpoint}') {
        margin-top: 40px;
      }

      .itemOuter {
        flex: 0 0 50%;
        padding: 0 12px 16px 12px;
        @include media('<=#{$moreBreakpoint}') {
          flex: 0 0 100%;
        }

        .item {
          background: #000000;
          border: 1px solid rgba(194, 201, 214, 0.26);
          border-radius: 16px;
          padding: 32px;
          @include media('<=#{$headerBreakpoint}') {
            padding: 24px;
          }

          .itemTitle {
            font-size: 24px;
            line-height: 130%;
            color: rgba(239, 242, 251, 0.92);
            @include media('<=#{$headerBreakpoint}') {
              font-size: 20px;
            }
          }

          .itemDesc {
            font-size: 18px;
            line-height: 130%;
            letter-spacing: -0.2px;
            color: #8994a9;
            margin-top: 8px;
            @include media('<=#{$headerBreakpoint}') {
              font-size: 16px;
            }
          }

          .itemButtonPlace {
            display: flex;
            flex-flow: row nowrap;
            margin-top: 24px;
          }
        }
      }
    }
  }
}

$footerBreapoint: 800px;
.section.footer {
  margin-top: 95px;
  padding: 39px 0;
  background: #101115;
  @include media('<=#{$footerBreapoint}') {
    margin-top: 64px;
    padding: 32px 0;
  }

  .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;

    .logo {
    }

    .navMenu {
      margin-top: 32px;
      display: flex;
      flex-flow: row nowrap;
      @include media('<=#{$footerBreapoint}') {
        flex-flow: column nowrap;
        align-items: center;
      }

      .navMenuLink {
        color: rgba(239, 242, 251, 0.92);
        text-decoration: none;
        &:not(:first-child) {
          margin-left: 32px;
        }

        @include media('<=#{$footerBreapoint}') {
          margin-left: 0;
          &:not(:first-child) {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }
    }
    .copyright {
      margin-top: 32px;
      padding-top: 32px;
      border-top: 1px solid rgba(194, 201, 214, 0.14);
      width: 100%;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: -0.2px;
      color: rgba(137, 148, 169, 0.8);
      text-align: center;
    }
  }
}
