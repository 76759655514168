@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.techWorks {
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;

  & > * {
    max-width: 412px;
  }

  .video {
    width: 100%;
    max-width: 360px;
  }

  .title {
    color: var(--Text-Primary, #eff2fb);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    /* Mobile/Titles/Title 2 Bold */
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 28.6px */
    letter-spacing: -0.2px;

    @media (min-width: 899px) {
      font-family: Inter;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 105%; /* 35.7px */
      letter-spacing: -0.4px;
    }
  }

  .desc {
    margin-top: 12px;
    color: var(--Text-Secondary, #8994a9);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    /* Mobile/Body/S Regular Body */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-bottom: 24px;

    @media (min-width: 899px) {
      color: var(--Text-Secondary, #8994a9);
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      /* Mobile/Body/M Regular Body */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }
  }

  .button {
    align-self: center;
  }
}
