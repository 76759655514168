@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.sections {
  max-width: 800px;
  .section {
    & + .section {
      margin-top: 40px;
    }
    .sectionTitle {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 7px;
    }
    .questions {
      .question {
        & + .question {
          margin-top: 15px;
        }
        .questionTitle {
          font-size: 20px;
          font-weight: normal;
          line-height: 1.4;
          color: #8d99a8;

          .link {
            @include link(#8d99a8, #8d99a8, dashed);
          }
        }

        .questionAnswer {
          margin-top: 6px;

          a {
            @include link(#f5ce20, #fff, solid);
          }

          p {
            line-height: 1.4;
            & + p {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
}

.haveQuestions {
  margin-top: 60px;
}
