@import 'styles/mixins.scss';
@import 'styles/vars.scss';

$headerBreakpoint: 600px;
$headerBreakpoint1: 400px;

img {
  max-width: 100%;
}

.section {
  overflow: hidden;

  .sectionTitle {
    font-weight: 700;
    font-size: 56px;
    line-height: 105%;
    // text-align: center;
    color: rgba(239, 242, 251, 0.92);
    @include media('<=#{$headerBreakpoint}') {
      text-align: left;
      font-size: 34px;
    }
    @include media('<=#{$headerBreakpoint1}') {
      font-size: 28px;
    }
  }

  .sectionDesc {
    margin-top: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    // text-align: center;
    letter-spacing: -0.2px;
    color: #8994a9;
    @include media('<=#{$headerBreakpoint}') {
      text-align: left;
      margin-top: 16px;
      font-size: 16px;
    }
  }
}

.wrapper {
  // box-sizing: content-box;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1120px + 16px * 2;
  width: 100%;
  margin: 0 auto;
}

.section.header {
  position: relative;
  height: 84px;
  @include media('<=#{$headerBreakpoint}') {
    height: 52px;
  }

  .headerInner {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;

    .wrapper {
      padding-top: 24px;
      padding-bottom: 16px;
      background-color: #000;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      @include media('<=#{$headerBreakpoint}') {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .logoPlace {
        font-size: 0;

        .logoBigLink {
          @include media('<=#{$headerBreakpoint}') {
            display: none;
          }
        }

        .logoSmallLink {
          @include media('>#{$headerBreakpoint}') {
            display: none;
          }
        }
      }

      .authMenu {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;

        .authMenuItem {
          &:not(:first-child) {
            margin-left: 6px;
          }

          @include media('<=350px') {
            margin-left: -6px !important;
          }

          .authMenuItemIcon {
            @include media('<=#{$headerBreakpoint}') {
              display: none;
            }
          }
        }
      }
    }
  }
}

.section.intro {
  margin-top: 53px;
  @include media('<=#{$headerBreakpoint}') {
    margin-top: 33px;
  }

  .wrapper {
    max-width: 800px + 16px * 2;
  }

  .finishTypes {
    margin-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: -16px;
    margin-right: -16px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    overflow-x: auto;
    gap: 8px;

    .finishType {
      padding: 8px 12px;
      border-radius: 24px;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      border: none;
      cursor: pointer;
      color: #eff2fb;
      background-color: rgba(194, 201, 214, 0.14);
      white-space: nowrap;

      &:hover {
        background-color: rgba(194, 201, 214, 0.24);
      }

      &.active {
        pointer-events: none;
        color: #1c1e21;
        background-color: #eff2fb;
      }
    }
  }

  $breakpoint: 800px;

  .crs {
    margin-top: 32px;
    display: flex;
    flex-flow: column nowrap;
    gap: 80px;

    .cr {
      .photo {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        display: block;
        margin-bottom: 24px;
      }

      .title {
        color: #eff2fb;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 41.6px */

        @include media('<=#{$breakpoint}') {
          font-size: 22px;
          line-height: 130%; /* 28.6px */
          letter-spacing: -0.2px;
        }

        a {
          color: #eff2fb !important;
          text-decoration-line: underline;
        }
      }

      .sn {
        margin-top: 8px;
        color: #8994a9;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
      }

      .statsBlocks {
        margin-top: 24px;
        display: flex;
        flex-flow: row nowrap;
        gap: 24px;

        @include media('<=#{$breakpoint}') {
          flex-flow: column-reverse nowrap;
          gap: 16px;
        }

        .statsBlock {
          flex: 1 1 50%;
          display: flex;
          flex-flow: column;
          align-items: stretch;

          .statsBlockTitle {
            color: #eff2fb;
            /* Mobile/Body/L Bold Body */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 25.2px */
            margin-bottom: 16px;
          }

          .statsBlockLines {
            display: flex;
            flex-flow: column;
            align-items: stretch;

            .statsBlockLine {
              display: flex;
              flex-flow: row nowrap;
              align-items: flex-start;
              justify-content: space-between;
              padding: 8px 0;
              gap: 12px;

              &:not(:last-child) {
                border-bottom: 1px solid #c2c9d61f;
              }
              .statsBlockLabel {
                color: #8994a9;
                /* Mobile/Body/M Regular Body */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
              }

              .statsBlockValue {
                color: #eff2fb;
                text-align: right;
                font-feature-settings: 'clig' off, 'liga' off;
                /* Mobile/Body/M Regular Body */
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
                white-space: nowrap;
              }
            }
          }
        }
      }

      .statsBlockGeneral {
      }

      .statsBlockInvestors {
        padding: 16px 16px 8px;
        border-radius: 16px;
        background: #1c1e21;
      }

      .content {
        margin-top: 28px;
        max-height: 200px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        gap: 16px;

        &.expanded {
          max-height: none;

          .contentTextWrapper {
            &:before {
              display: none;
            }
          }
        }

        .contentTextWrapper {
          flex: 1 1 100%;
          width: 100%;
          position: relative;
          overflow: hidden;

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60px;
            background: linear-gradient(rgba(#000, 0) 0%, #000 100%);
            z-index: 1;
          }

          .contentText {
            color: #eff2fb;
            font-feature-settings: 'clig' off, 'liga' off;
            text-overflow: ellipsis;
            /* Mobile/Longread/M Regular Longread */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%; /* 25.6px */

            a {
              color: #1a8cff !important;
              text-decoration: none;

              &:hover {
                opacity: 0.8;
              }
            }

            & > * {
              &:not(:last-child) {
                margin-bottom: 16px;
              }
            }
          }
        }

        .contentMore {
          flex: 0 0 auto;
        }
      }
    }
  }
}

$footerBreapoint: 800px;
.section.footer {
  margin-top: 95px;
  padding: 39px 0;
  background: #101115;
  @include media('<=#{$footerBreapoint}') {
    margin-top: 64px;
    padding: 32px 0;
  }

  .wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;

    .logo {
    }

    .navMenu {
      margin-top: 32px;
      display: flex;
      flex-flow: row nowrap;
      @include media('<=#{$footerBreapoint}') {
        flex-flow: column nowrap;
        align-items: center;
      }

      .navMenuLink {
        color: rgba(239, 242, 251, 0.92);
        text-decoration: none;
        &:not(:first-child) {
          margin-left: 32px;
        }

        @include media('<=#{$footerBreapoint}') {
          margin-left: 0;
          &:not(:first-child) {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }
    }
    .copyright {
      margin-top: 32px;
      padding-top: 32px;
      border-top: 1px solid rgba(194, 201, 214, 0.14);
      width: 100%;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: -0.2px;
      color: rgba(137, 148, 169, 0.8);
      text-align: center;
    }
  }
}
