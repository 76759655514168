@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.button {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  position: relative;
  text-align: center;
  transition: background-color 100ms ease;

  &.fullWidth {
    width: 100%;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    // font-weight: 500;
    text-align: center;
  }

  &.loading {
    pointer-events: none;
    opacity: 0.5;
    @include loader($borderWidth: 5px, $size: 30px, $color: $white);

    .content {
      opacity: 0.2;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.size-normal,
  &.size-normal-short {
    min-height: 56px;
    padding: 17px 40px;
    border-radius: 12px;

    .content {
      font-style: normal;
      // font-weight: 590;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: -0.2px;

      .icon {
        margin-right: 18px;
        margin-left: -16px;
      }
    }
  }

  &.size-small {
    min-height: 32px;
    padding: 7px 20px;
    border-radius: 8px;

    .content {
      font-style: normal;
      // font-weight: 590;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -0.2px;

      .icon {
        margin-right: 10px;
      }
    }
  }

  &.color-orange {
    background: #f2a60d;

    .content {
      color: #1c1e21;
    }

    &:hover {
      background: lighten(#f2a60d, 15%);
    }

    &:active {
      background: lighten(#f2a60d, 10%);
    }

    path {
      fill: #1c1e21 !important;
    }
  }

  &.color-blue {
    background: rgba(194, 201, 214, 0.14);

    .content {
      color: #1a8cff;

      .icon path {
        fill: #1a8cff;
      }
    }

    &:hover {
      background: lighten(rgba(194, 201, 214, 0.14), 15%);
    }

    &:active {
      background: lighten(rgba(194, 201, 214, 0.14), 10%);
    }
  }

  &.color-blue-without-background {
    .content {
      color: #1a8cff;

      .icon path {
        fill: #1a8cff;
      }
    }
  }

  &.color-white-outline {
    border: 1px solid rgba(239, 242, 251, 0.6);
    .content {
      color: rgba(239, 242, 251, 0.92);

      .icon path {
        fill: rgba(239, 242, 251, 0.6);
      }
    }
  }

  &.color-white {
    background: #eff2fb;
    .content {
      color: #1c1e21;

      .icon path {
        fill: #1c1e21;
      }
    }
  }
}
