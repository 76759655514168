@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.new {
  margin-bottom: 1em;
}

.companyResults {
  @include list;

  .cell {
    &:nth-child(1) {
      flex: 0 0 300px;
    }
    &:nth-child(2) {
      flex: 0 0 170px;
    }
    &:nth-child(3) {
      flex: 0 0 170px;
    }
    &:nth-child(4) {
      flex: 0 0 170px;
    }
    &:nth-child(5) {
      flex: 0 0 170px;
    }
    &:nth-child(6) {
      flex: 0 0 150px;
    }
  }
}
