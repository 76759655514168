@import 'styles/vars.scss';
@import 'styles/mixins.scss';

$colors: (
  'system-links-blue': $system-links-blue,
  'system-error-red': $system-error-red,
  'text-trietary': $text-trietary,
  'text-primary': $text-primary,
  'text-white': $text-white,
);

.link {
  transition: color 100ms ease;

  &.loading {
    pointer-events: none;
    opacity: 0.5;
  }

  &.disabled {
    pointer-events: none;
    color: $text-primary;
  }

  @each $colorName, $color in $colors {
    &.type-dashed-color-#{$colorName} {
      @include link($color, rgba($color, 0.6), dashed);
    }

    &.type-solid-color-#{$colorName} {
      @include link($color, rgba($color, 0.6), solid);
    }

    &.type-dotted-color-#{$colorName} {
      @include link($color, rgba($color, 0.6), dotted);
    }

    &.type-clear-color-#{$colorName} {
      @include link($color, rgba($color, 0.6), false);
    }
  }
}
