@import "styles/vars.scss";
@import "styles/mixins.scss";

.segment {
  h1.title {
    @include h500;
  }

  h2.title {
    @include h300;
  }

  h3.title {
    @include h200;
  }

  .header {
    display: flex;
    flex-flow: row nowrap;

    .title {
      flex: 1 1 100%;
    }

    .right {
      flex: 0 0 0;
    }

    & + .desc {
      margin-top: 0.3em;
    }

    & + .content {
      margin-top: 32px;
    }
  }

  .desc {
    @include sbw400;

    & + .content {
      margin-top: 32px;
    }
  }

  & + .segment {
    margin-top: 4em;
  }
}
