@import "styles/vars.scss";
@import "styles/mixins.scss";

.uploadGenDocData {
  @include field;

  .buttons {
    .uploadButton {
      @include media("<900px") {
        width: 100%;
      }

      input {
        display: none;
      }
    }
  }
}
