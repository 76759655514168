@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.general {
  .info {

  }

  .payoutRequest {
    margin-top: 1em;
  }
}

.referrals {
  @include table;
}

.referralTxs {
  @include table;
}
