@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.field {
  @include field;

  .textarea {
    max-width: 500px;
    min-height: 150px;
    @include rw400;
    font-family: $sans;
    border-radius: $inputBorderRadius;
    font-size: $defaultFontSize;
    width: 100%;
    padding: $inputPadding;
    background: #191a1d;
    border: 1px solid #898989;
    color: #d3e2e9;
    display: block;
    resize: vertical;
    outline: none;

    &::placeholder {
      color: #8d99a8;
    }

    &:focus {
    }

    &.fullWidth {
      max-width: none;
    }

    &.invalid {
      border-color: $system-error-red;
    }
  }

  &.disabled .textarea {
    pointer-events: none;
    opacity: 0.5;
  }
}
