@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.wrapper {
  max-width: 966px;
}

.dashboard {
  margin-top: -32px;
  margin-bottom: -64px;
}

.segment {
  padding: 24px 32px 32px;
  background: #191a1d;
  border-radius: 12px;

  & + .segment {
    margin-top: 24px;
  }

  @include media('<900px') {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;

    & + .segment {
      margin-top: 12px;
    }
  }
}

.documents {
  .list {
    .document {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 15px;
      background-color: #2a2c30;
      text-decoration: none;
      color: #d3e2e9;
      border-radius: 8px;

      & + .document {
        margin-top: 10px;
      }

      .icon {
        margin-right: 15px;
      }

      .name {
        color: #d3e2e9;
        text-decoration: none;
      }
    }
  }

  .more {
    margin-top: 32px;

    a {
      text-decoration: none;
      color: $orange;
      font-size: 16px;
    }
  }
}

.summary {
  // margin: -24px -32px -32px;
  margin: -10px -32px -20px;
  position: relative;
  overflow: hidden;

  @include media('<1000px') {
    max-width: 900px;

    // .item {
    //   width: 170px;
    //   // margin: 0;
    //   // padding: 0 0 1em 0;
    // }
  }

  .arrowWrapper {
    height: 100%;
    width: 68px;
    top: 0;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    user-select: none;
    // padding-bottom: 52px;

    &.leftArrowWrapper {
      background: linear-gradient(90deg, #191a1d 0%, rgba(25, 26, 29, 0) 100%);
      left: -68px;
      transition: left 200ms ease;
      justify-content: left;
      padding-left: 12px;

      &.visible {
        left: 0;
      }
    }

    &.rightArrowWrapper {
      background: linear-gradient(270deg, #191a1d 0%, rgba(25, 26, 29, 0) 100%);
      right: -68px;
      transition: right 200ms ease;
      justify-content: right;
      padding-right: 12px;

      &.visible {
        right: 0;
      }
    }
    .icon {
    }
  }

  .scrollable {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    overflow: auto;
    padding: 24px 0 32px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .item {
      padding: 0 0 0 32px;

      &:last-child {
        padding-right: 32px;
      }

      .value {
        font-size: 25px;
        font-weight: bold;
        word-wrap: nowrap;
        white-space: nowrap;
        line-height: 1.3;
      }

      .title {
        font-size: 16px;
        color: #8d99a8;
        margin-bottom: 12px;
        word-wrap: nowrap;
        white-space: nowrap;
      }
    }
  }
}

.summarySegment {
  overflow: hidden;
  margin-top: 30px;
  .coinsTopBgOuter {
    display: none;
  }
  @include media('<900px') {
    margin-top: 0;
  }

  @include media('<600px') {
    padding-top: 0;
    background: linear-gradient(180deg, #000 32.88%, #191a1d 100%);
    .coinsTopBgOuter {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -30px;
      margin-right: -30px;
      overflow: hidden;

      .coinsTopBg {
        max-width: 100%;
        margin-top: -104px;
        height: auto;
      }
    }
  }
}

.summaryButtonsPlace {
  // padding: 10px 32px 20px 32px;
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
  gap: 10px;

  @include media('<612px') {
    & > * {
      width: 100%;
    }
  }
}

.balanceItems {
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  gap: 30px;
  .balanceItem {
    .value {
      font-size: 25px;
      font-weight: bold;
      word-wrap: nowrap;
      white-space: nowrap;
      line-height: 1.3;
    }

    .title {
      font-size: 16px;
      color: #8d99a8;
      margin-bottom: 6px;
      word-wrap: nowrap;
      white-space: nowrap;
    }

    &.green {
      .value {
        color: #13bf81;
      }
    }
  }
}

.shortBalanceItems {
  // margin-top: 40px;
  display: flex;
  flex-flow: row wrap;
  padding-top: 20px;
  padding-bottom: 10px;
  gap: 30px;

  // @include media('<900px') {
  //   flex-flow: column;
  // }

  .balanceItem {
    .value {
      font-size: 25px;
      font-weight: bold;
      word-wrap: nowrap;
      white-space: nowrap;
      line-height: 1.3;
    }

    .title {
      font-size: 16px;
      color: #8d99a8;
      margin-bottom: 6px;
      word-wrap: nowrap;
      white-space: nowrap;
    }

    &.green {
      .value {
        color: #13bf81;
      }
    }
  }
}

.shortBalanceItems2 {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 20px;
  padding-bottom: 10px;
  gap: 32px;

  .mainItem {
    .total {
      color: #d3e2e9;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 100% */
      margin-bottom: 4px;
    }

    .free {
      color: #8d99a8;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }

  .scondaryItem {
    .values {
      .value {
        color: #13bf81;
        font-size: 16px;
        font-style: normal;
        font-weight: 590;
        line-height: 24px; /* 150% */

        &.sad {
          color: #d3e2e9;
        }
      }
    }

    .label {
      color: #8d99a8;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.properties {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  margin: 0 -23px -40px;

  .item {
    margin: 0 23px 40px 23px;
    width: 270px;

    .clickable {
      text-decoration: none;
      display: block;
      width: 100%;

      .photo {
        position: relative;
        width: 270px;
        height: 203px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .title {
        color: $primary;
        font-size: 20px;
        font-weight: bold;
        line-height: 27px;
        margin-top: 15px;
      }

      .info {
        margin-top: 0.5em;
        .infoItem {
          color: $primary;

          &:not(:last-child) {
            margin-bottom: 0.4em;
          }

          span {
            color: #8d99a8;
            margin-right: 8px;
          }
        }
      }

      &:hover {
        .title {
          color: $system-links-blue;
        }
      }
    }

    .newPaymentPlace {
      margin-top: 20px;

      .newPaymentButton {
        width: 100%;
      }
    }
  }

  @include media('<612px') {
    flex-flow: column nowrap;
    position: relative;
    margin-left: 0;
    margin-right: 0;

    .item {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      .photo {
        width: 100%;
        height: auto;
      }
    }
  }
}

.moreTxs {
  color: $orange;
  font-size: 16px;
  padding-top: 10px;
  white-space: nowrap;
  text-decoration: none;
  display: block;
}

.txsGroup {
  background-color: #191a1d;
  padding: 16px 24px 24px;
  margin-left: -20px;
  margin-right: -20px;

  .txsGroupTitle {
    font-size: 25px;
    color: $primary;
    margin-bottom: 20px;
    font-weight: bold;
  }

  & + .txsGroup {
    margin-top: 10px;
  }
}

.txs {
  .tx {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    & + .tx {
      margin-top: 30px;
    }

    .iconRound {
      flex: 0 0 40px;
      width: 40px;
      height: 40px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      margin-right: 16px;

      &.green {
        background: #13bf81;
      }
      &.blue {
        background: #2e56ff;
      }
      &.orange {
        background: $orange;

        .icon {
          path {
            fill: #212227;
          }
        }
      }
      &.gray {
        background: #8d99a8;
      }
    }
    .infoAndMoney {
      flex: 1 1 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: flex-start;

      .info {
        flex: 1 1 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;

        .infoMain {
          font-size: 16px;
          font-weight: 700;
          color: $primary;
        }
        .infoSecondary {
          margin-top: 5px;
          font-size: 14px;
          color: #8d99a8;

          a {
            @include link(#8d99a8, $orange, transparent);
            text-decoration: none;
          }
        }
        .infoDate {
          margin-top: 5px;
          font-size: 14px;
          color: #8d99a8;
        }
      }
      .money {
        flex: 0 0 0;
        font-size: 16px;
        padding-left: 10px;

        .moneyItem {
          white-space: nowrap;
          word-wrap: nowrap;
          color: $primary;
          text-align: right;

          & + .moneyItem {
            margin-top: 3px;
          }
        }

        &.green {
          .moneyItem {
            color: #13bf81;
          }
        }
      }
    }
  }
}

.referral {
  .intro {
    font-size: 16px;
    color: #8d99a8;
    line-height: 22px;

    b {
      color: #d3e2e9;
    }
  }
  .list {
    margin-top: 20px;
    padding: 17px;
    background: #2a2c30;
    border-radius: 12px;

    .listHeader {
      color: #8d99a8;
      font-size: 16px;
      line-height: 22px;
    }

    .listItems {
      margin-top: 16px;

      .listItem {
        font-size: 16px;
        line-height: 22px;
        color: #d3e2e9;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .empty {
    margin-top: 20px;
  }

  .blocks {
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;
    align-items: stretch;
    margin-left: -23px;
    margin-right: -23px;

    .block {
      max-width: 100%;
      flex: 0 0 50%;
      padding: 24px 23px 0;
      position: relative;

      @include media('<650px') {
        flex: 0 0 100%;
      }

      &.first {
        .blockInner {
          height: 100%;
          border-radius: 8px;
          background-color: $orange;
          padding: 16px 24px 24px;

          .blockIntro {
            font-size: 16px;
            line-height: 24px;
            color: #212227;
          }

          .link {
            display: flex;
            flex-flow: row nowrap;
            padding: 12px 16px 16px;
            background: #191a1d;
            border-radius: 12px;
            margin-top: 12px;
            cursor: pointer;
            align-items: center;

            .linkText {
              flex: 1 1 100%;
              text-decoration-line: underline;
              color: #d3e2e9;
              padding-right: 20px;
              word-break: break-all;
              line-break: anywhere;
            }

            .linkIcon {
              flex: 0 0 40px;
              width: 40px;
              height: 40px;
              border-radius: 20px;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      &.second {
        .blockInner {
          height: 100%;
          border-radius: 8px;
          background-color: #2a2c30;
          padding: 16px 24px 24px;
          overflow: hidden;
          position: relative;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: flex-start;

          * {
            z-index: 10;
            position: relative;
          }

          .image {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 5;

            @include media('<1100px') {
              right: -40px;
            }

            @include media('<1000px') {
              display: none;
            }

            @include media('<900px') {
              display: block;
            }
          }

          .blockIntro {
            padding-right: 150px;
            font-size: 16px;
            line-height: 22px;
            color: #d3e2e9;

            @include media('<1100px') {
              padding-right: 100px;
            }

            @include media('<1000px') {
              padding-right: 0;
            }

            @include media('<900px') {
              padding-right: 100px;
            }
          }

          .button {
            margin-top: 20px;
            padding: 10px 24px;
            background-color: $orange;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #212227;
            text-decoration: none;
            font-weight: bold;
            border-radius: 6px;
          }
        }
      }
    }
  }
}

.questions {
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  .intro {
    font-size: 16px;
    line-height: 24px;
    color: #8d99a8;
  }
  .button {
    margin-top: 20px;
    background: #ffcd1e;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    padding: 10px 17px;
    text-decoration: none;

    .buttonIcon {
      margin-right: 8px;
    }

    .buttonText {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #212227;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .message {
    margin-top: 20px;
  }
}

.topBg {
  // margin-top: -20px;
  margin-top: 12px;
  position: relative;

  @include media('<900px') {
    margin-top: 0px;
    background: linear-gradient(0deg, #191a1d 0%, rgba(29, 25, 25, 0) 100%);
    margin-left: -20px;
    margin-right: -20px;
  }

  &:before {
    width: 250px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    z-index: 1;
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

    // @include media("<800px") {
    //   width: 150px;
    // }
    @include media('<900px') {
      display: none;
    }
  }

  &:after {
    width: 250px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    z-index: 1;
    background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

    // @include media("<800px") {
    //   width: 150px;
    // }
    @include media('<900px') {
      display: none;
    }
  }

  .inner {
    .image {
      object-fit: cover;
      max-width: 100%;
      height: auto;
      min-height: 154px;
    }
  }
}

.bottomBg {
  margin-top: 20px;
  // margin-top: -20px;
  // position: relative;

  .inner {
    overflow: hidden;
    width: 100%;

    .image {
      object-fit: cover;
      max-width: 100%;
      height: auto;
      margin-bottom: -115px;

      @include media('<700px') {
        margin-bottom: -65px;
      }

      @include media('<500px') {
        margin-bottom: 0;
      }
    }
  }
}

.welcomeModal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .gratz {
    .icon {
      width: 100%;
      max-width: 358px;
      height: auto;
    }
  }

  .title {
    margin-top: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.2px;
    color: rgba(239, 242, 251, 0.92);
  }

  .panel {
    margin-top: 24px;
    padding: 12px 16px 16px 44px;
    background: rgba(194, 201, 214, 0.14);
    position: relative;
    border-radius: 8px;

    .icon {
      position: absolute;
      top: 14px;
      left: 14px;
    }
    .panelTitle {
      font-style: normal;
      font-weight: 590;
      font-size: 16px;
      line-height: 130%;
      /* identical to box height, or 21px */

      display: flex;
      align-items: flex-end;
      letter-spacing: -0.2px;

      /* Dark/Text/Primary */

      color: rgba(239, 242, 251, 0.92);
    }
    .panelDesc {
      margin-top: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      /* or 18px */

      // display: flex;
      // align-items: flex-end;
      letter-spacing: -0.2px;

      /* Dark/Text/Primary */

      color: rgba(239, 242, 251, 0.92);
    }
  }

  .buttonPlace {
    margin-top: 0px;

    & + .panel {
      margin-top: 32px;
    }

    .button {
      margin-top: 20px;
      background: #f2a40d;
      border-radius: 6px;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      padding: 10px 17px;
      text-decoration: none;
      border: none;
      cursor: pointer;

      .buttonIcon {
        margin-right: 8px;

        path {
          fill: #212227;
        }
      }

      .buttonText {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #212227;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}

.pleaseSignContract {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 40;

  .message {
    width: calc(100vw - 80px);
    max-width: 700px;
  }
}
