@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.uploadSingleFile {
  @include field;

  .previewPlace {
    display: block;
    margin-bottom: 0.3em;
  }

  .buttons {
    .uploadButton {
      @include media('<900px') {
        width: 100%;
      }

      input {
        display: none;
      }
    }
  }
}
