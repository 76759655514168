@import "styles/vars.scss";
@import "styles/mixins.scss";

.authLayout {
  height: 100%;

  // @include media(">=900px") {
  //   display: flex;
  //   flex-flow: row nowrap;
  //   // align-items: center;
  //   justify-content: center;
  //   // padding-top: 40px;
  //   // padding-bottom: 40px;

  //   .container {
  //     width: 424px;
  //   }
  // }
  display: flex;
  flex-flow: column;
  align-items: center;

  .header {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;

    @include media("<=800px") {
      display: none;
    }

    .logoPlaceDesktop {
      padding-top: 42px;
      padding-left: 54px;
      width: 100%;
      margin-bottom: -100px;
      position: relative;
      z-index: 2;
    }

    .topBg {
      max-width: 1000px;
      // margin-top: -20px;
      margin-top: 12px;
      position: relative;

      @include media("<500px") {
        margin-left: -20px;
        margin-right: -20px;
      }

      &:before {
        width: 250px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        z-index: 1;
        background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

        @include media("<800px") {
          width: 150px;
        }
        @include media("<500px") {
          display: none;
        }
      }

      &:after {
        width: 250px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        z-index: 1;
        background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

        @include media("<800px") {
          width: 150px;
        }
        @include media("<500px") {
          display: none;
        }
      }

      .inner {
        .image {
          object-fit: cover;
          max-width: 100%;
          height: auto;
          min-height: 154px;
        }
      }
    }
  }

  .container {
    display: flex;
    flex-flow: column;
    align-items: center;

    .logoPlaceMobile {
      padding-top: 40px;
      padding-bottom: 36px;

      @include media(">800px") {
        display: none;
      }

      .logo {
        border: none;
        font-size: 30px;
        font-weight: bold;

        .current {
          pointer-events: none;
          cursor: default;
        }
      }
    }

    .tabs {
      justify-content: center;
      width: 100%;
      margin-bottom: 32px;
      @include tabs;
    }

    .content {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding-left: $screenPadding;
      padding-right: $screenPadding;
      padding-bottom: 50px;

      .wrapper {
        min-height: 120px;
        width: 300px;
        max-width: 100%;
        position: relative;
      }
    }
  }
}
