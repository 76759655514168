@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.deletedAlert {
  margin-bottom: 1em;
}

.edit {
  margin-bottom: 1em;
}

.page {
  padding-bottom: 80px;
  max-width: 800px;
  .mainPic {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    border-radius: 16px;
    max-width: 540px;
  }

  .propertyTitle {
    color: #eff2fb;
    font-feature-settings: 'liga' off, 'clig' off;
    /* Desktop/Titles/Title 2 Bold */
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
    margin-bottom: 24px;

    @media (max-width: 899px) {
      color: var(--Text-Primary, #eff2fb);
      font-feature-settings: 'liga' off, 'clig' off;
      /* Mobile/Titles/Title 2 Bold */
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 28.6px */
      letter-spacing: -0.2px;
    }
  }

  .investPlace {
    .newPaymentPlace {
      .newPaymentButton {
      }
    }

    @media (max-width: 899px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      padding: 24px 24px 24px 24px;
      // gradient
      background: linear-gradient(180deg, rgba(#000, 0) 0%, #000 50%);
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: stretch;

      .newPaymentPlace {
        width: 100%;
        .newPaymentButton {
          width: 100%;
        }
      }
    }
  }

  .stats {
    display: flex;
    flex-flow: row nowrap;
    background-color: #1c1e21;
    border-radius: 16px;
    padding: 16px;
    margin-top: 24px;
    gap: 8px;

    .statsItem {
      flex: 0 0 50%;

      .statsItemLabel {
        margin-bottom: 8px;
        color: var(--Text-Secondary, #8994a9);
        font-feature-settings: 'liga' off, 'clig' off;
        /* Mobile/Body/S Regular Body */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
      }

      .statsItemValue {
        color: var(--Text-Primary, #eff2fb);
        font-feature-settings: 'liga' off, 'clig' off;
        /* Mobile/Body/L Bold Body */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
      }
    }
  }

  .props {
    margin-top: 16px;

    .propsItem {
      border-bottom: 1px solid rgba(#c2c9d6, 0.16);
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      padding: 12px 0;
      justify-content: space-between;
      gap: 8px;

      .propsItemLabel {
        color: var(--Text-Secondary, #8994a9);
        font-feature-settings: 'liga' off, 'clig' off;
        /* Mobile/Body/M Regular Body */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
      }
      .propsItemValue {
        color: var(--Text-Primary, #eff2fb);
        text-align: right;
        font-feature-settings: 'liga' off, 'clig' off;
        /* Mobile/Body/M Regular Body */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
      }
    }
  }

  .aboutPlace {
    margin-top: 24px;

    .aboutTitle {
      color: var(--Text-Primary, #eff2fb);
      font-feature-settings: 'liga' off, 'clig' off;
      /* Mobile/Titles/Title 3 Bold */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 26px */
      letter-spacing: -0.2px;
      margin-bottom: 12px;
    }

    .aboutPanel {
      background-color: #1c1e21;
      padding: 16px;
      border-radius: 16px;

      .aboutTextOuter {
        flex: 1 1 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        max-height: 300px;

        &.expanded {
          max-height: none;

          &:before {
            display: none;
          }
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 60px;
          background: linear-gradient(rgba(#1c1e21, 0) 0%, #1c1e21 100%);
          z-index: 1;
        }

        .aboutText {
          color: #eff2fb;
          font-feature-settings: 'clig' off, 'liga' off;
          text-overflow: ellipsis;
          /* Mobile/Longread/M Regular Longread */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 25.6px */

          a {
            color: #1a8cff !important;
            text-decoration: none;

            &:hover {
              opacity: 0.8;
            }
          }

          & > * {
            &:not(:last-child) {
              margin-bottom: 16px;
            }
          }
        }
      }

      .morePlace {
        margin-top: 12px;
        .moreLink {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          @include link(#f5ce20, #fff, none);
        }
      }
    }
  }

  .newsPlace {
    margin-top: 24px;

    .newsTitle {
      color: var(--Text-Primary, #eff2fb);
      font-feature-settings: 'liga' off, 'clig' off;
      /* Mobile/Titles/Title 3 Bold */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 26px */
      letter-spacing: -0.2px;
      margin-bottom: 12px;
    }
  }
}
