@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.uploadSingleImage {
  @include field;

  .previewPlace {
    width: 100px;
    height: 100px;
    border-radius: $inputBorderRadius;
    background: $light;
    padding: 10px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 0.3em;

    .preview {
      flex: 1 1 100%;
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .buttons {
    .uploadButton {
      @include media('<900px') {
        width: 100%;
      }

      input {
        display: none;
      }
    }
  }
}
