@import "styles/vars.scss";
@import "styles/mixins.scss";

.field {
  @include field;

  .inputPlace {
    position: relative;

    .iconPlace {
      position: absolute;
      top: 10px;
      left: 14px;
      width: 20px;
      height: 20px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      path {
        fill: #69718b;
      }
    }

    .input {
      max-width: 300px;
      border-radius: $inputBorderRadius;
      @include rw400;
      width: 100%;
      padding: $inputPadding;
      display: block;
      outline: none;
      background: #191a1d;
      border: 1px solid #191a1d;
      appearance: none;
      color: #d3e2e9;

      &::placeholder {
        color: #8d99a8;
      }

      &:focus {
        border-color: $orange;
      }

      &.fullWidth {
        max-width: none;
      }

      &.invalid {
        border-color: $system-error-red;
      }
    }
  }

  &.withIcon {
    .input {
      padding-left: 44px;
    }
  }

  &.disabled .input {
    pointer-events: none;
    opacity: 0.5;
  }
}
