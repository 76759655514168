@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.field {
  @include field;

  .select {
    input {
      font-family: $sans;
    }
  }

  &.disabled .input {
    pointer-events: none;
    opacity: 0.5;
  }
}
