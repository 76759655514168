@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.news {
  .newsPost {
    border-radius: 12px;
    background: #191a1d;
    padding: 18px 16px;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;

    & + .newsPost {
      margin-top: 16px;
    }

    .title {
      color: var(--mvp-dark-text-secondary, #8d99a8);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

      a {
        @include link(#8d99a8, #fff, none);
      }
    }

    .textOuter {
      max-height: 70px;
      overflow: hidden;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background: linear-gradient(rgba(#191a1d, 0) 0%, #191a1d 100%);
        z-index: 1;
      }

      &.expanded {
        max-height: none;

        &:before {
          display: none;
        }
      }

      .text {
        color: var(--mvp-dark-text-primary, #d3e2e9);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        position: relative;
        overflow: hidden;

        & > *:not(:last-child) {
          margin-bottom: 8px;
        }

        a {
          @include link(#f5ce20, #fff, none);
        }
      }
    }
    .linkPlace {
      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        @include link(#f5ce20, #fff, none);
      }
    }
  }
}

.wrapper {
  .switcher {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    padding-bottom: 8px;

    .switcherItem {
      text-decoration: none;
      padding: 8px 20px;
      border-radius: 24px;
      border: none;
      background: var(--fill-neutral-10, rgba(194, 201, 214, 0.14));
      color: var(--text-primary, #eff2fb);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      cursor: pointer;

      &.active {
        background: var(--control-neutral-rest, #eff2fb);
        color: var(--text-primary-invert, #1c1e21);
      }
    }
  }

  .newsAndDates {
    .newsAndDate {
      .date {
        color: var(--dark-elements-primary, #e9eaf1);
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 28.6px */
        letter-spacing: -0.2px;
        margin-top: 24px;
        margin-bottom: 16px;
      }
    }
  }
}
