@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.richText {
  position: relative;
  // max-width: 100%;
  // width: 100%;
  & > * {
    margin-top: 0.5em;

    &:first-child {
      margin-top: 0;
    }
  }

  p,
  ul,
  ol {
    max-width: 900px;
  }

  p {
    @include sbw500;
  }

  a {
    @include link(#d3e2e9, #d3e2e9, solid);
  }

  :global {
    p.secondary {
      @include rw400;
      color: $text-trietary;
    }
  }

  h1 {
    @include h500;
  }

  h2 {
    @include h300;
  }

  h3 {
    @include h200;
  }

  h4 {
    @include h100;
  }

  h5 {
    @include h100;
  }

  ul {
    list-style-type: disc;
    // list-style-position: inside;
    padding-left: 1em;
    @include sbw500;
  }

  ol {
    list-style-type: decimal;
    // list-style-position: inside;
    padding-left: 1em;
    @include sbw500;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 1em;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 1em;
  }

  ul li,
  ol li {
    margin-bottom: 0.3em;
  }

  pre {
    display: block;
    overflow-x: auto;
  }

  :global {
    .video {
      flex: 1 1 100%;
      font-size: 0;
      max-width: 900px;
      margin-top: 0.5em;

      .iframe-wrapper {
        position: relative;
        width: 100%;
        padding-top: 56%;

        iframe {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
