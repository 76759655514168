@use "sass:math";
@import "~include-media";
@import "styles/vars.scss";

@mixin link($color: $blue, $hoverColor: $red, $borderStyle: solid) {
  @if $borderStyle {
    border-bottom: 1px $borderStyle rgba($color, $linkBorderOpacity);
    border-color: rgba($color, $linkBorderOpacity);
  } @else {
    border: none;
  }
  text-decoration: none;
  color: $color;

  &:visited {
    color: $color;
  }

  &:hover,
  &:active {
    color: $hoverColor;
    @if $borderStyle {
      border-color: rgba($hoverColor, 1);
    }
  }
}

@mixin h1000 {
  font-size: 56px;
  line-height: 64px;
  font-weight: 700;
}

@mixin h900 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
}

@mixin h800 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}

@mixin h700 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
}

@mixin h600 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}

@mixin h500 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
}

@mixin h400 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

@mixin h300 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

@mixin h200 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

@mixin h100 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

@mixin rw800 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
}

@mixin rw700 {
  font-size: 22px;
  line-height: 34px;
  font-weight: 400;
}

@mixin rw600 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

@mixin rw500 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

@mixin rw400 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin rw300 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

@mixin rw200 {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

@mixin rn800 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

@mixin rn700 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
}

@mixin rn600 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

@mixin rn500 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

@mixin rn400 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

@mixin rn300 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

@mixin rn200 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

@mixin sbw800 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
}

@mixin sbw700 {
  font-size: 22px;
  line-height: 34px;
  font-weight: 500;
}

@mixin sbw600 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}

@mixin sbw500 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}

@mixin sbw400 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

@mixin sbw300 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

@mixin sbw200 {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
}

@mixin sbn800 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

@mixin sbn700 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
}

@mixin sbn600 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}

@mixin sbn500 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

@mixin sbn400 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

@mixin sbn300 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

@mixin sbn200 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

@mixin centered($size) {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5 * $size;
  margin-left: -0.5 * $size;
}

@mixin shadow3 {
  box-shadow: 0px 4px 16px rgba(8, 11, 48, 0.2);
}

@mixin loader($borderWidth: 5px, $size: 30px, $color: $white) {
  &:after {
    content: "";
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: -9999em;
    transform: translateZ(0);
    animation: spin 1.1s infinite linear;
    border-top: $borderWidth solid rgba($color, 0.2);
    border-right: $borderWidth solid rgba($color, 0.2);
    border-bottom: $borderWidth solid rgba($color, 0.2);
    border-left: $borderWidth solid $color;
    width: $size;
    height: $size;
    left: 50%;
    margin-left: math.div(-$size, 2);
    top: 50%;
    margin-top: math.div(-$size, 2);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@mixin field {
  .label {
    display: block;
    @include sbw400;
    margin-bottom: 8px;
    color: $primary;
  }

  .error {
    margin-top: 8px;
    color: $system-error-red;
    @include rn300;

    & + .hint {
      margin-top: 4px;
    }
  }

  .hint {
    margin-top: 8px;
    color: $text-trietary;
    @include rn300;
  }

  &.disabled {
    .label,
    .error {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}

@mixin list {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  @include media("<900px") {
    overflow-x: visible;
  }

  .header {
    @include media("<900px") {
      display: none;
    }

    .row {
      .cell {
        color: $primary;
        border-top: 1px solid rgb(76, 76, 76);
        border-bottom: 1px solid rgb(76, 76, 76);
      }
    }
  }

  .card {
    display: none;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid rgb(76, 76, 76);
    position: relative;

    &.colorGreen {
      .title {
        color: $green;
      }
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        background: rgb(76, 76, 76);
      }
    }

    &:not(:last-child) {
      margin-bottom: 18px;
    }

    @include media("<900px") {
      display: block;
    }

    .cardHeader {
      display: flex;
      flex-flow: row nowrap;
      padding-right: 32px;
      margin-bottom: 5px;

      .number {
        @include sbw300;
        color: $text-trietary;
        position: relative;
        flex: 0 0 0;
        display: flex;
        flex-flow: row nowrap;
      }

      .subTitle {
        flex: 1 1 100%;
        @include rw300;
        color: $primary;
        padding-left: 14px;
        position: relative;

        &:before {
          content: "・";
          position: absolute;
          left: 0;
          @include sbw300;
          width: 14px;
          text-align: center;
        }
      }
    }

    .title {
      @include sbw400;
      color: $primary;
    }

    .info {
      .infoItem {
        margin-top: 2px;
        display: flex;
        flex-flow: row nowrap;

        .icon {
          width: 16px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          margin-right: 6px;

          svg {
            width: 14px;
          }

          svg path {
            fill: $text-quaternary;
          }
        }

        .text {
          color: $text-trietary;
          @include rw300;
          padding-top: 1px;
        }
      }
    }

    .menu {
      position: absolute;
      top: 7px;
      right: 7px;

      .dropdown {
        @include dropdown;
        right: 0;
      }

      .links {
        @include links;
      }
    }
  }

  .row {
    display: flex;
    flex-flow: row nowrap;

    &.colorGreen {
      color: $green;
    }

    @include media("<900px") {
      display: none;
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        .cell {
          background: rgb(76, 76, 76);
        }
      }
    }

    .cell {
      @include rn400;
      box-sizing: border-box;
      padding: 10px;

      &.nowrap {
        white-space: nowrap;
      }

      &.green {
        color: $green;
      }

      &.red {
        color: $red;
      }

      .hint {
        @include rn300;
        color: $text-trietary;
        margin-top: 2px;
      }
    }
  }

  .items {
    .row {
      .cell {
        color: $primary;
        border-bottom: 1px solid rgb(76, 76, 76);
      }
    }
  }
}

@mixin table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
  }

  thead {
    tr {
      border-bottom: 1px solid rgb(76, 76, 76);
      // border-top: 1px solid $grayLight;

      th {
        font-weight: bold;
        text-align: left;
        padding: 0 5px 10px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgb(76, 76, 76);

      &.clickable {
        cursor: pointer;

        &:hover {
          background: rgb(76, 76, 76);
        }
      }

      td {
        padding: 10px 5px;
        // font-size: 14px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.nowrap {
          white-space: nowrap;
        }

        &.green {
          color: $green;
        }

        &.red {
          color: $red;
        }
      }

      &.more {
        border-bottom: none;

        td {
          padding-bottom: 0;
        }
      }
    }
  }
}

@mixin tabs {
  border-bottom: 1px solid $grayLight;
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 10px;

  @include media("<900px") {
    margin-left: -$screenPadding;
    margin-right: -$screenPadding;
    padding-right: $screenPadding;
    padding-left: $screenPadding;
  }

  .tab {
    & + .tab {
      margin-left: 20px;
    }
    .link {
      @include sbw400;
      @include link($text-trietary, $text-primary, false);
      padding-bottom: 11px;
      border-bottom: 2px solid transparent;
      z-index: 10;

      &.current {
        color: $text-primary;
        position: relative;
        border-color: $brand-green;
        pointer-events: none;
        cursor: default;
      }
    }
  }
}

@mixin dropdown {
  position: absolute;
  display: block;
  @include shadow3;
  background: rgb(36, 36, 36);
  border-radius: 4px;
  z-index: 10;
  width: 260px;
  padding: 8px 0;

  &.hidden {
    display: none;
  }

  &.toTop {
    bottom: 100%;
  }

  &.toBottom {
    top: 100%;
  }
}

@mixin links {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  .delimiter {
    padding: 12px 20px;
    width: 100%;
    position: relative;

    &:before {
      content: "";
      background: #36393f;
      height: 1px;
      width: 100%;
      display: block;
    }
  }

  .item {
    position: relative;
    width: 100%;

    & + .item {
      margin-top: 8px;
    }

    .link {
      padding-left: 22px;
      padding-right: 20px;
      min-height: 44px;
      padding-top: 6px;
      padding-bottom: 6px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &.current {
        background-color: #36393f;
        pointer-events: none;
      }

      &.red {
        .iconPlace {
          svg path {
            fill: $red;
          }
        }

        .text {
          color: $red;
        }
      }

      .iconPlace {
        width: 24px;
        height: 24px;
        flex: 0 0 24px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        svg path {
          fill: #69718b;
        }

        &.green {
          svg path {
            fill: $brand-green;
          }
        }

        &.red {
          svg path {
            fill: $system-error-red;
          }
        }
      }

      .text {
        margin-left: 14px;
        @include rn400;
        color: $primary;

        &.green {
          color: $brand-green;
        }

        &.red {
          color: $system-error-red;
        }
      }

      &:hover {
        .text {
          color: $orange;
        }

        .iconPlace {
          svg path {
            fill: $orange;
          }
        }
      }
    }
  }

  &.compressed {
    .item {
      & + .item {
        margin-top: 0;
      }
    }
  }
}
