@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.loader {
  &.type-hidden {
    display: none;
  }

  &.type-page {
    display: block;
    width: 100%;
    min-height: inherit;
    height: 100%;
    position: relative;
    @include loader($borderWidth: 5px, $size: 60px, $color: $gray);
  }

  &.type-section {
    display: block;
    height: 5em;
    width: 100%;
    position: relative;
    @include loader($borderWidth: 5px, $size: 60px, $color: $gray);
  }
}
