@import 'styles/mixins.scss';
@import 'styles/vars.scss';

.list {
  .item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
