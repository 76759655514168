@import "styles/vars.scss";
@import "styles/mixins.scss";

.clearLayout {
  min-height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .content {
    padding-top: 32px;
    padding-left: $screenPadding;
    padding-right: $screenPadding;
    padding-bottom: 50px;
  }
}
