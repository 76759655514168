@import "styles/mixins.scss";
@import "styles/vars.scss";

.new {
  margin-bottom: 1em;
}

.users {
  @include list;

  .cell {
    &:nth-child(1) {
      flex: 0 0 300px;
    }
    &:nth-child(2) {
      flex: 0 0 300px;
    }
    &:nth-child(3) {
      flex: 0 0 170px;
    }
    &:nth-child(4) {
      flex: 0 0 150px;
    }
  }
}
