@import 'styles/vars.scss';
@import 'styles/mixins.scss';

$headerBreakpoint: 600px;
$headerBreakpoint1: 400px;

.wrapper {
  // box-sizing: content-box;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1120px + 16px * 2;
  width: 100%;
  margin: 0 auto;
}

.docsLayout {
  min-height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;

  .header {
    position: relative;
    height: 84px;
    @include media('<=#{$headerBreakpoint}') {
      height: 52px;
    }

    .headerInner {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1000;

      .wrapper {
        padding-top: 24px;
        padding-bottom: 16px;
        background-color: #000;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        @include media('<=#{$headerBreakpoint}') {
          padding-top: 8px;
          padding-bottom: 8px;
        }

        .logoPlace {
          font-size: 0;

          .logoBigLink {
            @include media('<=#{$headerBreakpoint}') {
              display: none;
            }
          }

          .logoSmallLink {
            @include media('>#{$headerBreakpoint}') {
              display: none;
            }
          }
        }

        .authMenu {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: center;

          .authMenuItem {
            &:not(:first-child) {
              margin-left: 6px;
            }

            @include media('<=350px') {
              margin-left: -6px !important;
            }

            .authMenuItemIcon {
              @include media('<=#{$headerBreakpoint}') {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .content {
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 50px;
    width: 100%;
    max-width: 1120px + 16px * 2;
  }
}
