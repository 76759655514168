@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.uploadMultipleImages {
  @include field;

  .previews {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 0.3em;
    margin-right: -0.3em;

    .previewPlace {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: $inputBorderRadius;
      background: $light;
      padding: 10px;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 0.3em;
      margin-right: 0.3em;

      .preview {
        flex: 1 1 100%;
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .delete {
        position: absolute;
        top:0;
        right:0;
        width: 30px;
        height: 30px;

        &:before, &:after {
          content: '';
          width: 20px;
          height: 4px;
          background: $red;
          top: 13px;
          left: 5px;
          position: absolute;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .buttons {
    .uploadButton {
      @include media('<900px') {
        width: 100%;
      }

      input {
        display: none;
      }
    }
  }
}
