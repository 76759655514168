@import "styles/vars.scss";
@import "styles/mixins.scss";

.uploadMultipleFiles {
  @include field;

  .previewPlace {
    display: block;
    padding-bottom: 0.3em;
  }

  .buttons {
    .uploadButton {
      @include media("<900px") {
        width: 100%;
      }

      input {
        display: none;
      }
    }
  }
}
