@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.formItems {
  display: flex;

  &.direction-column {
    flex-flow: column;
    //align-items: flex-start;

    & > * {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &.direction-row {
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;

    & > * {
      margin-bottom: 20px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}
