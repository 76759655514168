@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.button {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  text-align: center;
  transition: background-color 100ms ease;

  &.size-normal {
    min-height: 48px;
    padding: 12px 24px;
    @include sbw400;
  }

  &.size-small {
    min-height: 40px;
    padding: 8px 16px;
    @include rw400;
  }

  &.size-small-icon {
    min-height: 40px;
    padding: 10px 10px;
    @include rw400;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &.size-tiny {
    min-height: 32px;
    padding: 8px 16px;
    @include rw400;
  }

  &.size-tiny-icon {
    min-height: 26px;
    padding: 5px 5px;

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  &.size-clear-tiny-icon {
    padding: 0;

    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &.square {
    width: 2.7em;
    padding: 0;
  }

  &.fullWidth {
    width: 100%;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  &.color-orange {
    background: $orange;
    color: #212227;

    &:hover {
      background: lighten($orange, 15%);
    }

    &:active {
      background: lighten($orange, 10%);
    }
  }

  &.color-white {
    background: #eff2fb;
    color: #1c1e21;

    &:hover {
      background: lighten(#eff2fb, 15%);
    }

    &:active {
      background: lighten(#eff2fb, 10%);
    }
  }

  &.color-blue {
    background: rgba(112, 184, 255, 0.14);
    color: #1a8cff;

    &:hover {
      background: lighten(rgba(112, 184, 255, 0.14), 15%);
    }

    &:active {
      background: lighten(rgba(112, 184, 255, 0.14), 10%);
    }
  }

  &.color-brand-green {
    background: $brand-green;
    color: $text-white;

    &:hover {
      background: lighten($brand-green, 15%);
    }

    &:active {
      background: lighten($brand-green, 10%);
    }
  }

  &.color-system-error-red {
    background: $system-error-red;
    color: $text-white;

    &:hover {
      background: lighten($system-error-red, 15%);
    }

    &:active {
      background: lighten($system-error-red, 10%);
    }
  }

  &.color-system-pure-white {
    background: $system-pure-white;
    color: $text-trietary;

    .icon path {
      fill: $text-trietary;
    }

    &:hover {
      background: darken($system-pure-white, 15%);
      color: $text-white;

      .icon path {
        fill: $text-white;
      }
    }

    &:active {
      background: darken($system-pure-white, 10%);
      color: $text-white;

      .icon path {
        fill: $text-white;
      }
    }
  }

  &.color-neutrals-20 {
    background: $neutrals-20;
    color: $text-primary;

    .icon path {
      fill: $text-primary;
    }

    &:hover {
      background: lighten($neutrals-20, 3%);
    }

    &:active {
      background: lighten($neutrals-20, 1%);
    }
  }

  &.color-clear-text-quaternary {
    color: $text-quaternary;
    background-color: transparent;

    .icon path {
      fill: $text-quaternary;
    }

    &:hover {
      .icon path {
        fill: $system-links-blue;
      }
    }

    &:active {
      .icon path {
        fill: $system-links-blue;
      }
    }
  }

  &.color-clear-text-orange {
    color: $primary;
    background-color: transparent;

    .icon path {
      fill: $primary;
    }

    &:hover {
      .icon path {
        fill: $orange;
      }
    }

    &:active {
      .icon path {
        fill: $orange;
      }
    }
  }

  &.color-outlined {
    background: $system-pure-white;
    border: 1px solid $border-dark;
    color: $text-primary;

    &:hover {
      border-color: lighten($border-dark, 3%);
    }

    &:active {
      border-color: lighten($border-dark, 1%);
    }
  }

  &.color-outlined-red {
    background: $system-pure-white;
    border: 1px solid $border-dark;
    color: $system-error-red;

    &:hover {
      border-color: lighten($border-dark, 3%);
    }

    &:active {
      border-color: lighten($border-dark, 1%);
    }
  }

  &.loading {
    pointer-events: none;
    opacity: 0.5;
    @include loader($borderWidth: 5px, $size: 30px, $color: $white);

    .content {
      opacity: 0.2;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.buttonsPlace {
  .buttons {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: -7px;
    margin-right: -15px;
    align-items: center;

    & > * {
      margin-right: 15px;
      margin-bottom: 7px;
    }

    @include media('<900px') {
      flex-flow: column nowrap;
      align-items: center;
      margin-right: 0;

      & > * {
        margin-right: 0;
      }

      & > .button {
        width: 100%;
      }

      & > :not(.button) {
        margin-top: 17px;

        & + .button {
          margin-top: 17px;
        }
      }
    }
  }
}
