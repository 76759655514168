@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.dropdown {
  @include dropdown;
  left: 20px;
}

.links {
  @include links;
}

.dashboardLayout {
  height: 100%;
  display: flex;
  flex-flow: column;

  .overlay {
    content: '';
    position: fixed;
    top: -25%;
    width: 200%;
    height: 200%;
    z-index: 40;
    transition: background-color 200ms ease, left 0ms ease 0ms;
    left: -25%;
    display: block;
    background: rgba(0, 0, 0, 0.3);

    &.hidden {
      left: -9999%;
      background: rgba(0, 0, 0, 0);
      transition: background-color 200ms ease, left 0ms ease 200ms;
    }
  }

  .mobileMenu {
    transition: margin-left 200ms ease;
    width: 260px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 16px;
    padding-bottom: 32px;
    overflow-y: auto;
    margin-left: 0;
    background: #2a2c30;
    z-index: 50;

    &.hidden {
      margin-left: -270px;
    }
  }

  .header {
    display: none;
    flex: 0 0 48px;

    @include media('<900px') {
      display: block;
    }

    .container {
      position: fixed;
      z-index: 30;
      background: $system-pure-white;
      top: 0;
      left: 0;
      width: 100%;
      height: 48px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: stretch;
      border-bottom: 1px solid $border-light;
      background: #2a2c30;
      border-bottom: 1px solid #36393f;

      .menuButtonPlace {
        flex: 0 0 58px;
        // border-right: 1px solid $border-light;

        .menuButton {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          path {
            fill: #f1f4fb;
          }
        }
      }

      .logoPlace {
        flex: 1 1 100%;

        .logo {
          height: 100%;
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          //justify-content: center;
          padding-left: 1px;
          font-weight: bold;
          gap: 12px;
          color: #e9eaf1;
          font-feature-settings: 'liga' off, 'clig' off;
          /* Mobile/Body/M Bold Body */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%; /* 22.4px */

          img {
            // height: 28px;
          }

          .logoSquare {
          }

          .logoName {
            margin-bottom: -2px;
          }
        }
      }

      .createButtonPlace {
        flex: 0 0 64px;

        .createButton {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .cols {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 100%;
    width: 100%;
    height: 100%;

    @include media('<900px') {
      height: auto;
    }

    .left {
      flex: 0 0 300px;
      height: 100%;
      position: relative;

      @include media('<900px') {
        display: none;
      }

      .container {
        padding-top: 32px;
        padding-bottom: 16px;
        //overflow-y: auto;
        background: #2a2c30;
        position: fixed;
        top: 0;
        left: 0;
        width: 300px;
        height: 100%;
        overflow: auto;

        .topAndBottom {
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          height: 100%;

          .top {
            padding-bottom: 20px;
          }

          .bottom {
            padding-top: 20px;
            // padding-bottom: 20px;
          }
        }

        .leftColHeader {
          display: flex;
          flex-flow: row nowrap;
          padding-left: 30px;
          padding-right: 30px;
          margin-bottom: 30px;

          .logo {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 16px;
            font-size: 30px;
            font-weight: bold;
            color: #e9eaf1;
            font-feature-settings: 'liga' off, 'clig' off;
            /* Mobile/Titles/Title 3 Bold */
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%; /* 26px */
            letter-spacing: -0.2px;

            .logoSquare {
              margin-left: -5px;
            }

            .logoName {
              margin-bottom: -1px;
            }
          }
        }
      }
    }

    .right {
      flex: 1 1 100%;
      height: 100%;
      overflow-y: auto;
      min-width: 0;
      padding-left: 56px;
      padding-right: 20px;
      padding-top: 32px;
      padding-bottom: 64px;
      transition: filter 200ms ease;

      @include media('<900px') {
        height: auto;
        overflow-y: visible;
        padding-left: 20px;
      }
    }
  }
}

.createModalInner {
  display: flex;
  justify-content: center;
  align-items: center;
}
