@import 'styles/vars.scss';
@import 'styles/mixins.scss';

.checkboxes {
  @include field;

  &.direction-row {
    .items {
      display: flex;
      flex-flow: row wrap;
      margin-right: -10px;
      margin-bottom: -5px;

      .checkbox {
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }

  &.direction-column {
    .items {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      margin-bottom: -5px;

      .checkbox {
        margin-bottom: 5px;
      }
    }
  }
}

.checkbox {
  font-size: $defaultFontSize;

  &.bold {
    font-weight: 500;
  }
}
