@import 'styles/vars.scss';
@import 'styles/mixins.scss';
@import '~reset-css';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~photoswipe/dist/photoswipe.css';

html,
body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $sans;
  font-size: $defaultFontSize;
  background: $defaultBackgroundColor;
  color: $defaultTextColor;
  line-height: $defaultLineHeight;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

b {
  font-weight: bold;
}

sup {
  vertical-align: super;
  font-size: 0.7em;
}

.wrapall {
  word-wrap: break-word;
  word-break: break-all;
}
