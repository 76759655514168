@import 'styles/mixins.scss';
@import 'styles/vars.scss';
@import '../DashboardPage/styles.module.scss';

.layout1 {
  display: flex;
  flex-direction: column;

  .header {
    max-width: 966px;
  }
}
